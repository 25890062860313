/* eslint-disable */
import React from 'react'
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import Header from "../components/header.js";
import SEO from "../components/seo.js";
import GeneralFooter from '../components/generalFooter';
import Layout from '../components/layout'

// Import paragraphs
import ImageLeftTextRight from '../paragraphs/image_left_text_right.js';
import TextLeftImageRight from '../paragraphs/text_left_image_right.js';
import ImageLeftTextRightCentered from '../paragraphs/image_left_text_right_centered.js';
import BlockQuote from '../paragraphs/block_quote.js';
import BodyText from '../paragraphs/body_text.js';
import FullWidthImageImg from '../paragraphs/full_width_image_img.js';
import CenteredTitleWithSubheader from '../paragraphs/centered_title_with_subheader.js';
import BlogImage from '../paragraphs/blog_image.js';
import Gallery from '../paragraphs/gallery.js';
import BlogImagesTiled from '../paragraphs/blog_images_tiled.js';
import TallSingleImage from '../paragraphs/tall_single_image.js';
import VideoBlock from '../paragraphs/video_block.js';

import sendEvent from '../helpers/sendAnalytics';

class blogPost extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let coupon = document.getElementById("blogCoupon");
    if(coupon) {
      document.getElementById('blogCoupon').classList.add('active')
    }

    if (typeof window !== 'undefined' && window.saq ) {
      if (this.props.data.nodeBlog.id === '12ad53d5-27c2-578a-9d7d-7aabea6af5a1' || this.props.data.nodeBlog.id === '23e8849f-8e84-5271-84d9-ecd1f7999095' ||  this.props.data.nodeBlog.id === '55c7759e-2aff-5e72-b265-fa55df1fccea' || this.props.data.nodeBlog.id === '80562765-f604-5012-9d31-b110c7c0d694' ) {
        saq('ts', '8eJKPfIsZ2HU6UWEgvsy0A');
      }
    }
  }

  componentWillUnmount() {
    let coupon = document.getElementById("blogCoupon");
    if(coupon) {
      document.getElementById('blogCoupon').classList.remove('active')
    }
  }

  sendLink(event){
    sendEvent({
      ga: {
        category: 'Bring_Comfort_Home',
        action: 'Click',
        label: event.target.href
      }
    })
  }


  render() {

    return (
      <Layout className="page-wrapper">
      {(this.props.data.nodeBlog.field_is_there_a_coupon === true) ? (
      <div id="blogCoupon" className="top-header blog" >
        <div className="top-header-info">
          <div className="top-header-left">
            <p className="top-header-text-left-title">Save with Scotties<sup>®</sup>!</p>
          </div>
          <div className="top-header-right">
            <div className="top-header-right-wrapper">
              <a href="/coupon" onClick={() => sendEvent({
                ga: {
                  category: 'Bring_Comfort_Home',
                  action: 'Theme_Filter',
                  label: 'Coupon Click'
                }
              })} className="button" target='_blank'>Get Coupon</a>
            </div>
          </div>
        </div>
      </div>
      ) : null}
      <Header retailers={this.props.data.allNodeStore.edges}></Header>
      <SEO meta={this.props.data.nodeBlog.field_meta_tags} />
      <div style={{clear:'both'}} />
      <div className="blog-paragraph-wrapper">
        <div className="blog-details">
          <div className="blog-author">
            <div className="blog-author-image">
              { (this.props.data.nodeBlog.relationships.field_author_avatar && this.props.data.nodeBlog.relationships.field_author_avatar.localFile) ?
                <img src={this.props.data.nodeBlog.relationships.field_author_avatar.localFile.childImageSharp.sizes.src} />
              : null }
            </div>
            <div className="blog-author-info">
              <div className="blog-author-info-name">{this.props.data.nodeBlog.field_author}</div>
              <div className="blog-author-info-email"><a href={this.props.data.nodeBlog.field_author_website} onClick={() => sendEvent({
                ga: {
                  category: 'Bring_Comfort_Home',
                  action: 'Theme_Filter',
                  label: 'Arthor_Website'
                }
              })} className="blog-author-info-email-link" target="_blank">{this.props.data.nodeBlog.field_author_website}</a></div>
            </div>
          </div>
          <div className="blog-title intro-bold dark-grey-text font-36">
            <h1>{this.props.data.nodeBlog.title}</h1>
          </div>
          <div className="blog-date section-body-text blue-text">{this.props.data.nodeBlog.created}</div>
        </div>

        {this.props.data.nodeBlog.relationships.field_paragraph.map(paragraph => {
          switch(paragraph.__typename) {
            case 'paragraph__image_left_text_right':
              return (
                <ImageLeftTextRight key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__centered_title_with_subheader':
              return (
                <CenteredTitleWithSubheader key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__full_width_image':
              return (
                <FullWidthImageImg key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__block_quote':
              return (
                <BlockQuote key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__body_text':
              return (
                <BodyText key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__blog_image':
              return (
                <BlogImage key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__gallery':
              return (
                <Gallery key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__image_left_text_right_centered':
              return (
                <ImageLeftTextRightCentered key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__blog_images_tiled':
              return (
                <BlogImagesTiled key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__tall_single_image':
              return (
                <TallSingleImage key={paragraph.id} data={paragraph} />
              );
              break;
            case 'paragraph__video_block':
              return (
                <VideoBlock url={paragraph.field_video_url} key={paragraph.id} data={paragraph} />
              );
              break;
          }
        })}
      </div>

      {(this.props.data.nodeBlog.field_full_body_text) ? (
        <div className="full-body-text_wrapper">
          <div className="full body-text" onClick={(event) => { this.sendLink(event) }} dangerouslySetInnerHTML={{__html: this.props.data.nodeBlog.field_full_body_text.value }}></div>
        </div>
      ) : null}

      <img className="fb-pixel view-content" height="1" width="1" src="https://www.facebook.com/tr?id=199508674022010&ev=ViewContent&noscript=1"/>

      <GeneralFooter />

    </Layout>
    )
  }
}

export default blogPost

export const query = graphql`
query BlogTemplate($slug: String!) {
  nodeBlog(path: {alias: { eq: $slug } }) {
    id
    title
    field_author
    field_author_website
    field_is_there_a_coupon
    field_full_body_text {
      value
    }
    created(formatString: "MMM DD, YYYY")
    relationships {
      field_author_avatar {
        localFile {
          childImageSharp {
            sizes(maxWidth: 50) {
              src
            }
          }
        }
      }
      field_paragraph {
        __typename,
        ... on paragraph__body_text {
          id,
          field_body_text {
            value
          }
        }
        ... on paragraph__full_width_image {
          id,
          field_full_width_img_caption
          relationships {
            field_full_width_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1400, quality: 85) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__tall_single_image {
          id,
          field_tall_single_caption
          relationships {
            field_tall_single_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 600, quality: 85) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__gallery {
          id,
          field_how_many_images_to_show
          relationships {
            field_gallery_slide {
              relationships{
                field_slide_image {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 1275, quality: 85) {
                        src
                      }
                      original {
                        src
                      }
                    }
                  }
                }
              }
              field_slide_caption
            }
          }
        }
        ... on paragraph__blog_image {
          id,
          field_blog_float
          field_blog_image_caption
          relationships {
            field_blog_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 400, quality: 85) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__blog_images_tiled {
          id,
          field_left_image_caption
          field_right_image_caption
          relationships {
            field_tiled_left_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 400, quality: 85) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
            }
            field_tiled_right_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 400, quality: 85) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
            }

          }
        }
      }
    }
  }
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
