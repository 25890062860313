/* eslint-disable */
import React from 'react';
import ReactPlayer from 'react-player'

class VideoBlock extends React.Component {

  constructor(props){
    super(props)
  }

  sendEvent(){
    if (window.ga) {
      window.ga('send', 'event', {
        eventCategory: 'Bring_Comfort_Home',
        eventAction: 'Click',
        eventLabel: 'Video_View',
      })
    }
  }

  render() {
    const url = this.props.url;

    return (
      <ReactPlayer onClick={() => { this.sendEvent() }} url={url} wrapper="video-wrapper"/>
    )
  }
}

export default VideoBlock
