/* eslint-disable */
import React from "react";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import "slick-carousel/slick/slick.css";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: null,
      initialImage: null,
      showGallery: false,
      isOpen: false,
      images: [],
      photoIndex:0
    }

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(index) {
    this.setState({
      showGallery: true,
      initialImage: index
    });
  }

  handleCloseModal() {
    this.setState({
      showGallery: false,
      initialImage: null,
      isOpen: false,
      currentImage: null
    });
  }



  render() {

    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: (this.props.data.field_how_many_images_to_show) ? (parseInt(this.props.data.field_how_many_images_to_show)) : 3,
      focusOnSelect: true,
      centerMode: true,
      draggable: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    let images = []

    var gal = this.props.data.relationships.field_gallery_slide

    gal.forEach(gallery => {
      images.push({
        url: gallery.relationships.field_slide_image.localFile.childImageSharp.original.src,
        caption: gallery.field_slide_caption
      });
    })

    return (

      <div>
        <Slider {...settings}>
          {gal.map((gallery, index) => {
            return(
              <div key={index} onClick={() => this.handleOpenModal(index)}>
                  <img src={gallery.relationships.field_slide_image.localFile.childImageSharp.sizes.src} alt={gallery.field_slide_caption} />
              </div>
            )
          })}
        </Slider>
        {this.state.showGallery && (
          <Lightbox
            mainSrc={images[this.state.photoIndex].url}
            nextSrc={images[(this.state.photoIndex + 1) % images.length].url}
            prevSrc={images[(this.state.photoIndex + images.length - 1) % images.length].url}
            imageCaption={images[this.state.photoIndex].caption}
            onCloseRequest={() => this.setState({ showGallery: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );

  }
}

export default Gallery
