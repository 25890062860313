/* eslint-disable */
import React from 'react';

class BodyText extends React.Component {
  render() {

    return (
      <div className="body-text" dangerouslySetInnerHTML={{__html: this.props.data.field_body_text.value }}></div>
    )
  }
}

export default BodyText
