/* eslint-disable */
import React from 'react';

class BlockQuote extends React.Component {
  render() {
    return (
      <div className="block-quote">
        <div className="block-quote-text section-body-text">
          {this.props.data.field_long_text}
        </div>
      </div>
    )
  }
}

export default BlockQuote
